import { createContext, ReactNode } from "react";

export type Breadcrumb = {
  text: string;
  link?: string;
  icon?: ReactNode;
};

export type ContainerParameters = {
  containerName: string;
};

export const DefaultInsurancePaymentContext = createContext({
  savePaymentsConfig: (_serviceLineId: string) => {
    return false;
  },
} as InsurancePaymentContext);

export type InsurancePaymentContext = {
  savePaymentsConfig: (serviceLineId: string) => boolean | undefined;
};

export type InsurancePaymentRenderParameters = {
  hostApp: string;
  browserHistory: any;
  organizationId: string;
  practiceId: string;
  setBreadCrumbs: (breadcrumbs: Breadcrumb[]) => void;
  setHeader: (title: string) => void;
  idpToken: () => Promise<string>;
  initializeSavePaymentsConfigCallback: (
    savePaymentsConfig: (serviceLineId: string) => boolean,
  ) => void;
  initializePaymentsConfigHasChangesCallback: (
    paymentsConfigHasChanges: () => boolean,
  ) => void;
};

export type InsurancePaymentInterface = ContainerParameters &
  InsurancePaymentRenderParameters;
