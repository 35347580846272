import * as serviceWorker from "./serviceWorker";
import App from "./app/layout/App";
import ReactDOM from "react-dom/client";
import { InsurancePaymentInterface } from "./serviceInterface";

let currentRoot: ReactDOM.Root | null = null;

//@ts-ignore
window.renderInsurancePayment = (props: InsurancePaymentInterface) => {
  const root = ReactDOM.createRoot(
    document.getElementById(props.containerName) as HTMLElement,
  );
  currentRoot = root;
  root.render(
    <div>
      <App
        hostApp={props.hostApp}
        organizationId={props.organizationId}
        practiceId={props.practiceId}
        browserHistory={props.browserHistory}
        setBreadCrumbs={props.setBreadCrumbs}
        setHeader={props.setHeader}
        idpToken={props.idpToken}
        initializeSavePaymentsConfigCallback={
          props.initializeSavePaymentsConfigCallback
        }
        initializePaymentsConfigHasChangesCallback={
          props.initializePaymentsConfigHasChangesCallback
        }
      />
    </div>,
  );
  serviceWorker.unregister();
};

//@ts-ignore
window.unmountInsurancePayment = () => {
  if (currentRoot) {
    currentRoot.unmount();
  }
};
